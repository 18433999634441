<script>
    let business;
    let bannerBusiness = [];
    // let banners = new Map();
    let banners = [];
    let hidden = false;
    import { onMount } from "svelte";
    onMount(async () => {
        console.log("Hello from svelte!");
        let b = JSON.parse(localStorage.getItem("business"));
        if (b && b.length > 0) {
            business = b;
            getBanners();
        } else {
            getBusiness();
        }
        setInterval(function () {
            checkPath();
        }, 1500);
    });

    function checkPath() {
        if (window.location.pathname != "/") {
            hidden = true;
        } else {
            hidden = false;
        }
    }

    function getBusiness() {
        let url = "https://eosmarketplace.io/api/business";
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                business = JSON.parse(xhr.responseText).result;
                localStorage.setItem("business", JSON.stringify(business));
                getBanners();
            }
        };
        xhr.send();
    }

    async function getBanners() {
        let url_eosio =
            "https://api-leap.eospglmlt.com/v1/chain/get_table_rows";
        let xhr = new XMLHttpRequest();
        xhr.open("POST", url_eosio);
        xhr.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded"
        );
        let data =
            '{"json":true, "code": "eosmarketplc", "table": "banner", "scope": "eosmarketplc"}';
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                var z = JSON.parse(xhr.responseText);
                bannerBusiness = z.rows.sort(
                    (b, a) => parseFloat(a.remains) - parseFloat(b.remains)
                );
                bannerBusiness.forEach(getData);
            }
        };
        xhr.send(data);
    }

    async function getData(value) {
        let i;
        for (i = 0; i < business.length; i++) {
            if (business[i].account === value.account) {
                console.log("found", value.account);
                await getInfo(business[i]._id, value.account);
                return true;
            }
        }
    }

    async function getInfo(id, accountName) {
        let url = "https://eosmarketplace.io/api/business/" + id;
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                let data = JSON.parse(xhr.responseText).result;
                console.log("banner info", data);
                // banners.set(accountName, data);
                // banners.push({accountName: data});
                // banners = [...banners, {accountName: data}]
                for (var x = 0, max = bannerBusiness.length; x < max; x++) {
                    if (bannerBusiness[x].account === accountName) {
                        // bannerBusiness[x].data = data;
                        bannerBusiness[x].data = data;
                    }
                }
 
            }
        };
        xhr.send();
    }
</script>

{#if !hidden}
    <section class=" ">
        <div>
            <!-- bannerBusiness:  {bannerBusiness.length} -->
            {#each bannerBusiness as b}
                <!-- {JSON.stringify(b)} -->
                {#if b.data}
                    <a
                        rel="noreferrer"
                        href="https://eosmarketplace.io/#/business/{b.data
                            .account}"
                        target="_blank"
                    >
                        <div
                            class="center business-container flex-md-nowrap justify-content-center justify-content-md-start"
                        >
                            <img
                                alt="business_banner"
                                class="business-image mb-4 mr-md-4"
                                src="https://eosmarketplace.io{b.data.image}"
                            />
                            <div>
                                <h3>
                                    {b.data.title}
                                </h3>
                               <span class="banner-text">  {b.data.description}</span>
                            </div>
                        </div>
                    </a>
                {/if}
                
            {/each}
        </div>
        <div />
    </section>
{/if}

<style>
    section {
        background: #191919;
        color: white;
        font-family: Roboto, sans-serif;
        padding-left: 2vw;
    }

    h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
    }

    img {
        height: 100%;
        width: 100%;
    }

    a:hover,
    a:visited,
    a:link,
    a:active {
        color: white;
        text-decoration: none;
    }

    a:hover .business-container {
        border: #bc1e4059 1px solid;
        padding: 10px;
        transition: all 0.2s ease;
    }

    a .business-container {
        border: #191919 1px solid;
        padding: 10px;
    }

    /* .button {
        width: 100px;
        height: 30px;
        z-index: 1;
        background: #fdb201;
        border: 1px solid #000000;
        box-sizing: border-box;
        box-shadow: 0 4px 4px #000000;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button a {
        color: black;
    } */

    /*.bg-two {*/
    /*    position: absolute;*/
    /*    top: 120vh;*/
    /*    background-image: url("./images/back_type_2.png");*/
    /*    background-size: cover;*/
    /*    background-position: top center;*/
    /*    background-repeat: no-repeat;*/
    /*    width: 100%;*/
    /*}*/
 
    .bannerContainer {
        width: 100%;
        height: 120vh;
        background-image: url("./images/main_back.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-bottom: 160px;
    }


    .center{
       align-items: center; 
       left: 50%;
    }

    .banner-text {
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        inline-size: 50vw;
    }
    @media (max-width: 768px) {
        .banner-text {
            align-items: center;
        inline-size: 90vw;
    }
    h3{
        text-align: center;
    }
}
h3{
    text-align: center;
}
    .banner {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    

    .business-container {
        left:50%;
        display: flex;
        flex-wrap: wrap;
        margin: 56px 0;
        background: #191919;
        align-items: center;
        width: 90vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    @media (max-width: 768px) {
        .business-container {
        display: flex;
        flex-wrap: wrap;
        margin: 56px 0;
        background: #191919;
        align-items: center;
        width: 90vw;
    }
    }


    .template-empty:before {
        content: "Loading...";
    }

    .business-image {
        max-width: 360px;
        max-height: 200px;
    }

    .footer {
        display: flex;
        align-items: flex-end;
        min-height: 480px;
    }
</style>
